<template>
  <div
    id="editUser"
    class="modal fade"
    role="dialog"
    aria-modal="true"
    aria-hidden="true"
    data-backdrop="static"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t('user.edit_user') }} <strong>{{ currentUser.full_name }}</strong>
          </h5>
        </div>
        <div class="d-flex justify-content-center p-2">
          <div
            ref="profilePic"
            class="profile-pic"
          >
            <label for="avatar">
              <i class="fas fa-camera fa-2x" />
            </label>
            <input
              id="avatar"
              type="file"
              accept=".jpg, .jpeg, .png"
              @change="loadAvatar($event)"
            >
          </div>
        </div>
        <div v-show="isRegistration">
          <div class="modal-body">
            <InputText
              v-model="params.first_name"
              input-id="first_name"
              :label-text="$t('user.first_name')"
              :value-input="params.first_name"
              :e-msg="editUserErrors['first_name'] | toString"
            />
            <InputText
              v-model="params.last_name"
              input-id="last_name"
              :label-text="$t('user.last_name')"
              :value-input="params.last_name"
              :e-msg="editUserErrors['last_name'] | toString"
            />
            <InputText
              v-model="params.email"
              input-id="email"
              :label-text="$t('user.email')"
              :value-input="params.email"
              :input-mode="'email'"
              :e-msg="editUserErrors['email'] | toString"
            />
            <InputText
              v-model="params.birthday"
              input-id="birthday"
              :label-text="$t('user.birthday')"
              :value-input="params.birthday"
              :e-msg="editUserErrors['birthday'] | toString"
            />
            <InputText
              v-model="params.phone"
              input-id="phone"
              :label-text="$t('user.phone')"
              :value-input="params.phone"
              :input-mode="'tel'"
              :e-msg="editUserErrors['phone'] | toString"
            />
            <InputText
              v-model="params.identity_card"
              :input-id="'identity_card'"
              :label-text="$t('user.identity_card')"
              :value-input="params.identity_card"
              :e-msg="editUserErrors['identity_card'] | toString"
            />
            <InputText
              v-model="params.address"
              :input-id="'address'"
              :label-text="$t('user.address')"
              :value-input="params.address"
              :e-msg="editUserErrors['address'] | toString"
            />
            <InputSelect2
              v-model="params.building_unit_id"
              :input-id="'building_unit_id'"
              :label-text="$t('user.unit_number')"
              :options="collectionBuildingUnits"
              :selected="params.building_unit_id"
              :required="true"
              type-select="single_select"
              :place-holder="$t('user.select_building_unit')"
              :e-msg="editUserErrors['building_unit_id'] | toString"
            />
          </div>
          <div class="modal-footer">
            <button
              v-if="!currentUser.status_active"
              type="button"
              class="btn btn-warning mr-auto"
              @click="submitRestore"
            >
              {{ $t('user.restore') }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t('user.close') }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="submitConfirm"
            >
              {{ $t('user.submit') }}
            </button>
          </div>
        </div>
        <div v-show="isConfirm">
          <div class="modal-body">
            <FieldConfirm
              :label-text="$t('user.first_name')"
              :value="params.first_name"
            />
            <FieldConfirm
              :label-text="$t('user.last_name')"
              :value="params.last_name"
            />
            <FieldConfirm
              :label-text="$t('user.email')"
              :value="params.email"
            />
            <FieldConfirm
              :label-text="$t('user.birthday')"
              :value="params.birthday"
            />
            <FieldConfirm
              :label-text="$t('user.phone')"
              :value="params.phone"
            />
            <FieldConfirm
              :label-text="$t('user.identity_card')"
              :value="params.identity_card"
            />
            <FieldConfirm
              :label-text="$t('user.address')"
              :value="params.address"
            />
            <FieldConfirm
              :label-text="$t('user.unit_number')"
              :value="collectionBuildingUnits[params.building_unit_id]"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="backToRegistration"
            >
              {{ $t('user.back') }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="submitForm"
            >
              {{ $t('user.save_change') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");
import InputText from "../components/inputText";
import FieldConfirm from "components/fieldConfirm";
import InputSelect2 from "components/inputSelect2.vue";
import show_flash_mixins from "../mixins/show_flash";

export default {
  components: {
    InputText,
    FieldConfirm,
    InputSelect2
  },
  filters: {
    toString: function (value) {
      if (!value) return "";

      return value.toString();
    }
  },
  mixins: [show_flash_mixins],
  data: function () {
    return {
      isRegistration: true,
      isConfirm: false,
      params: {},
      avatar: "",
      editUserErrors: {},
      defaultAvatar: "https://d22tsqse9dyb2k.cloudfront.net/sample_icon.png",
      flashMsg: ""
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser,
      isValid: (state) => state.isValid,
      collectionBuildingUnits: (state) => state.building_units
    })
  },
  watch: {
    currentUser: function (val) {
      const self = this;
      this.params = {
        id: val.id,
        birthday: val.birth_day,
        email: val.email,
        first_name: val.first_name,
        last_name: val.last_name,
        phone: val.phone,
        identity_card: val.identity_card,
        lock_version: val.lock_version,
        address: val.address,
        building_unit_id: Object.keys(self.collectionBuildingUnits).find(
          (key) => self.collectionBuildingUnits[key] === val.unit_number
        )
      };
      if (val.avatar?.url) {
        this.$refs.profilePic.style.backgroundImage = `url(${val.avatar.url})`;
      } else {
        this.$refs.profilePic.style.backgroundImage = `url(${this.defaultAvatar})`;
      }
    }
  },
  mounted() {
    const self = this;
    $("#editUser").on("hidden.bs.modal", function () {
      self.editUserErrors = {};
    });
  },
  methods: {
    ...mapActions(["editUser", "restoreUser"]),
    submitForm: async function () {
      $("#loading").removeClass("d-none");
      $("#body-content").addClass("body-opacity-50");
      let { status, errors } = await this.editUser({
        params: this.handleParams()
      });
      this.editUserErrors = errors || {};
      this.isRegistration = true;
      this.isConfirm = false;
      $("#loading").addClass("d-none");
      $("#body-content").removeClass("body-opacity-50");
      if (status === "ok") {
        $("#editUser").modal("hide");
        this.show_flash(this.isValid);
      } else {
        try {
          if (typeof this.editUserErrors.base === "object") {
            this.flashMsg = this.editUserErrors.base.join(", ");
          }
          this.show_flash(this.isValid);
        } catch (error) {
          console.error(error);
        }
      }
    },
    submitConfirm: function () {
      this.isRegistration = false;
      this.isConfirm = true;
    },
    backToRegistration: function () {
      this.isConfirm = false;
      this.isRegistration = true;
    },
    handleParams: function () {
      const self = this;
      const formData = new FormData();
      Object.keys(this.params).forEach(function (key) {
        formData.append(`user[${key}]`, self.params[key]);
      });
      return formData;
    },
    loadAvatar: function (event) {
      let image_file = event.target.files[0];
      this.params.avatar = image_file;
      this.$refs.profilePic.style.backgroundImage = `url(${URL.createObjectURL(
        image_file
      )})`;
    },
    async submitRestore() {
      const { data, status } = await this.restoreUser({
        params: { id: this.currentUser.id }
      });
      if (status === "ok") {
        $("#editUser").modal("hide");
        this.show_flash(true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$circleSize: 10em;
$radius: 50%;
$fontColor: white;
$shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px, rgb(209, 213, 219) 0 0 0 1px inset;

.profile-pic {
  position: relative;
  color: transparent;
  width: $circleSize;
  height: $circleSize;
  border-radius: $radius;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  text-align: center;
  box-shadow: $shadow;

  input {
    display: none;
  }

  label {
    width: $circleSize;
    height: $circleSize;
    border-radius: $radius;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: $shadow;
    z-index: 10;
  }

  span {
    display: inline-block;
  }

  label:hover {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    color: $fontColor;
    transition: background-color 0.2s ease-in-out;
    border-radius: $radius;
  }
}
</style>
