import user from '../../api/users';
import mixin from '../../mixins/handle_errors';

const state = {
  params: {},
  users: [],
  showPaginate: false,
  totalPages: 0,
  totalCount: 0,
  currentPage: 0,
  currentUser: {},
  isValid: true,
  building_units: {},
  newUsers: [
    {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      birthday: '',
      identity_card: '',
      building_unit_id: '',
      avatar: '',
      address: ''
    }
  ],
  errorMessages: {},
  importUsers: {},
  errorsImportUsers: {}
};

const actions = {
  submitFormSearch({ commit }, payload) {
    commit('setParams', payload);
    const params = { q: payload.params, page: payload.page };
    const path = `/users?${jQuery.param(params)}`;
    history.pushState('', '', path);
    user.loadWithCondition(params, (data) => {
      commit('setUsers', data);
    });
  },
  setCurrentUser({ commit }, user) {
    commit('setCurrentUser', user);
  },
  async editUser({ commit, dispatch, state }, payload) {
    let result = {};
    await user.updateUser(payload.params, (data) => {
      if (data.status === 'ok') {
        commit('setCurrentUser', data.user);
        commit('setStatusResponse', true);
        dispatch('submitFormSearch', { params: state.params, page: state.currentPage });
      } else {
        commit('setStatusResponse', false);
      }
      result = { status: data.status, errors: data.error };
    });
    return result;
  },
  deleteUser({ commit, dispatch, state }, payload) {
    user.deleteUser(payload.params, (data) => {
      dispatch('submitFormSearch', { params: state.params, page: state.currentPage });
    });
  },
  async restoreUser({ commit, dispatch, state }, payload) {
    let response = {};
    await user.restoreUser(payload.params, (data) => {
      dispatch('submitFormSearch', { params: state.params, page: state.currentPage });
      response = data;
    });
    return response;
  },
  getBuildingUnits({ commit }) {
    user.loadBuildingUnits({}, (building_units) => {
      commit('setBuildingUnits', building_units);
    });
  },
  setNewUser({ commit }) {
    commit('setNewUser');
  },
  async createUsers({ commit }, payload) {
    await user.createUsers(payload.params, (data) => {
      if (data.status === 'ok') {
        window.location.href = '/users';
      } else {
        commit('setErrors', mixin.methods.handle_errors(data.errors));
      }
    });
  },
  deleteNewUser({ commit }, index) {
    commit('deleteNewUser', index);
  },
  importFile({ commit }, payload) {
    user.importFile(payload, (data) => {
      if (data.status === 'ok') {
        window.location.href = '/users';
      } else {
        commit('setResultImport', data);
      }
    });
  },
  clearErrorMessage({ commit }, payload) {
    const index = payload;
    commit('clearErrorMessage', index);
  },
  loadDetailUser({ commit }, payload) {
    user.loadDetailUser(payload, (data) => {
      commit('setCurrentUser', data.user);
    });
  }
};

const mutations = {
  setParams(state, payload) {
    state.params = payload.params;
    state.currentPage = Number(payload.page);
  },
  setCurrentPage(state, page) {
    state.currentPage = Number(page);
  },
  setUsers(state, data) {
    state.currentPage = Number(data.current_page);
    state.totalPages = data.total_pages;
    state.totalCount = data.total_count;
    state.showPaginate = data.total_pages > 1;
    state.users = data.data;
  },
  setCurrentUser(state, user) {
    state.currentUser = user;
  },
  setStatusResponse(state, status) {
    state.isValid = status;
  },
  setForm(state, params) {
    state.params = params;
  },
  setBuildingUnits(state, building_units) {
    state.building_units = building_units;
  },
  setNewUser(state) {
    state.newUsers.push({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      birthday: '',
      identity_card: '',
      building_unit_id: '',
      avatar: ''
    });
  },
  deleteNewUser(state, index) {
    state.newUsers.splice(index, 1);
  },
  setErrors(state, errorMessages) {
    state.errorMessages = errorMessages;
  },
  setResultImport(state, data) {
    state.importUsers = data.users;
    state.errorsImportUsers = data.errors;
  },
  clearErrorMessage(state, index) {
    state.errorMessages[index] = null;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
