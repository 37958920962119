const messages = {
  vi: {
    user: {
      no: 'STT',
      new_user: 'Thêm khách hàng mới',
      add_user: 'Thêm',
      delete: 'Xóa khách hàng',
      first_name: 'Họ và tên đệm',
      last_name: 'Tên',
      email: 'Email',
      phone: 'Số điện thoại',
      birthday: 'Ngày sinh',
      identity_card: 'CMND',
      unit_number: 'Số phòng',
      select_building_unit: 'Chọn phòng',
      close: 'Đóng',
      submit: 'Lưu lại',
      restore: 'Kích hoạt lại',
      back: 'Trở về',
      save_change: 'Lưu thay đổi',
      edit_user: 'Chỉnh sửa',
      delete_user: 'Xóa khách hàng',
      user_id: 'Mã KH',
      created_at: 'Ngày tạo',
      edit_or_delete: 'Sửa / Xóa',
      active: 'Online',
      self: 'Khách hàng',
      deleted: 'Đã xóa!',
      info: 'Thông tin',
      errors: 'Lỗi',
      import_user: 'Nhập hàng loạt',
      not_errors: 'Không lỗi',
      confirmation: {
        perform_delete_user: 'Bạn có muốn xóa khách hàng ',
        yes: 'Có',
        close: 'Đóng'
      },
      avatar: 'Ảnh đại diện',
      search: 'Tìm kiếm',
      name: 'Họ tên',
      placeholder_name: 'Ví dụ: A hoặc Nguyễn Văn',
      placeholder_phone: 'Ví dụ: 0123456789',
      placeholder_building_unit: 'Ví dụ: 100, 101, 102-105',
      error_unit_number: 'Vui lòng nhập số',
      error_phone: 'Vui lòng nhập số  từ 1-12 ký tự',
      show_user: 'Thông tin khách hàng',
      edit_user: 'Chỉnh sửa thông tin khách hàng',
      active: 'Hoạt động',
      inactive: 'Không hoạt động',
      all: 'Tất cả',
      reset: 'Reset',
      status: 'Trạng thái',
      address: 'Địa chỉ thường trú'
    },
    floor: {
      self: 'Tầng',
      edit_building_unit: 'Sửa thông tin phòng',
      unit_number: 'Số phòng',
      building_unit_area: 'Diện tích(m2)',
      building_unit_holder: 'Chủ phòng',
      select_holder: 'Chọn chủ phòng',
      building_unit_status: 'Trạng thái',
      close: 'Đóng',
      submit: 'Lưu lại',
      back: 'Trở về',
      save_change: 'Lưu thay đổi',
      hired: 'Đã thuê',
      empty: 'Còn trống',
      deposited: 'Đã đặt cọc',
      total_building_units: 'Tổng phòng',
      total_building_units_description: 'Tổng số phòng trong tòa nhà',
      occupied_building_units: 'Phòng đã thuê',
      occupied_building_units_description: 'Một khách hiện đang ở trong phòng',
      no_show_building_units: 'Đã đặt cọc',
      no_show_building_units_description: 'Một khách đã đặt phòng nhưng chưa đăng ký hoặc Check-in',
      vacant_and_ready_building_units: 'Trống và Sẵn sàng',
      vacant_and_ready_building_units_description: 'Phòng trống và sẵn sàng để nhận phòng'
    },
    contract: {
      contract_id: 'Mã hợp đồng',
      holder_name: 'Chủ phòng',
      unit_number: 'Số phòng',
      building_unit_price: 'Giá phòng',
      deposited_money: 'Số tiền cọc',
      start_date: 'Ngày bắt đầu',
      end_date: 'Ngày kết thúc',
      duplicate_and_edit: 'Nhân bản + chỉnh sửa',
      extend: 'Gia hạn',
      details: 'Chi tiết',
      terminate: 'Hủy',
      status: 'Trạng thái',
      detail_contract: 'Chi tiết hợp đồng',
      id: 'ID',
      month_rented: 'Số tháng đã thuê',
      month_remain: 'Số tháng còn lại',
      total_money: 'Tổng tiền',
      close: 'Đóng',
      new_contract: 'Tạo mới',
      select_holder: 'Chủ phòng',
      select_building_unit: 'Chọn phòng',
      deposited: 'Đã cọc',
      submit: 'Lưu lại',
      number_of_month: 'Số tháng',
      or_input: 'hoặc nhập',
      back: 'Trở về',
      extend_contract: 'Gia hạn hợp đồng số',
      confirm: 'Xác nhận',
      terminate_contract: 'Chấm dứt hợp đồng',
      confirmation: {
        perform_terminate_contract: 'Bạn có muốn chấm dứt hợp đồng',
        yes: 'Có',
        close: 'Không'
      },
      defaultMonth: {
        month: '{count} tháng'
      },
      search: 'Tìm kiếm',
      unit_number: 'Số phòng',
      placeholder_unit_number_search: 'Ví dụ: 100, 101, 102-105',
      placeholder_holder_name_search: 'Ví dụ: Nguyen Van A',
      active: 'Hoạt động',
      inactive: 'Không hoạt động',
      all: 'Tất cả',
      reset: 'Reset',
      document: 'Tài liệu',
      sub_merchant: {
        title: "Ngân hàng"
      }
    },
    invoice: {
      building_unit: 'Phòng',
      deposited: 'Đặt cọc',
      price: 'Tiền phòng',
      electric: 'Điện',
      water: 'Nước',
      internet: 'Internet',
      num: 'Số lượng',
      service: 'Phí dịch vụ',
      total: 'Tổng',
      reduce: 'Giảm trừ',
      holder: 'Chủ phòng',
      begin_number: 'Số đầu',
      end_number: 'Số cuối',
      use: 'Sử  dụng',
      into_money: 'Thành tiền',
      note: 'Ghi chú',
      not_contract: 'Không có hợp đồng',
      check_form: 'Kiểm tra',
      submit_form: 'Lưu lại',
      error_msg: 'Vui lòng kiểm tra lại dữ liệu phòng {building_unit}',
      success_msg: 'Dữ liệu thỏa mãn',
      choose_building_unit: 'Vui lòng chọn phòng',
      batch_create: 'Tạo mới hàng loạt',
      invoice_id: 'Mã hóa đơn',
      month: 'Tháng',
      year: 'Năm',
      total_income: 'Tổng thu',
      export_print: 'Xuất/In',
      no: 'TT',
      print_title: 'THÔNG BÁO TIỀN THUÊ PHÒNG',
      building_unit_no: 'Phòng số',
      service: 'Dịch vụ',
      unit: 'Đơn vị',
      qty: 'SL',
      unit_price: 'Đơn giá',
      building_unit_price: 'Tiền thuê phòng',
      ele_price: 'Chi phí điện',
      wat_price: 'Chi phí nước',
      paf_price: 'Chi phí gửi xe',
      int_price: 'Chi phí internet',
      ser_price: 'Phí dịch vụ',
      total_1: 'Cộng (1)',
      reduce_or_increase: 'Chi phí giảm trừ hoặc tăng thêm (nếu có)',
      reduce_1: 'Giảm trừ',
      total_2: 'Cộng (2)(2-1)',
      print_total: 'Tổng cộng (1+2)',
      num_month: '1',
      from_to: '(Từ số {begin} đến số {end})',
      payment_deadline: '- Thời hạn nộp tiền thuê phòng: từ ngày 01-03 hàng tháng (quý)',
      transfer_infor: '- Thông tin chuyển khoản:',
      account_name: '+ Tên tài khoản: {text}',
      account_number: '+ Số TK: {0}. {1} - Chi nhánh {2}',
      transfer_content: '+ Nội dung chuyển khoản:',
      content: 'Phòng số .......... Tên khách thuê .......... Nộp tiền nhà tháng ..........',
      date_time: 'Hà Nội, ngày .......... tháng .......... năm ..........',
      payer: 'Người nộp',
      receiptor: 'Người thu',
      print: 'In hóa đơn',
      export_image: 'Xuất Ảnh',
      copy_success: 'Sao chép thành công',
      generate_success: 'Tạo ảnh thành công',
      add_invoice: 'Tạo hóa đơn',
      new_invoice: 'Tạo hóa đơn mới',
      unit_number: 'Phòng số',
      building_unit_price: 'Giá phòng',
      day_used_per_month: 'Ngày sử dụng',
      electric_start: 'Số điện đầu kì',
      electric_end: 'Số điện cuối kì',
      water_start: 'Số nước đầu kì',
      water_end: 'Số nước cuối kì',
      unit_price_internet: 'Đơn giá internet',
      unit_price_parking_fee: 'Phí gửi xe',
      quantity_parking: 'Số lượng xe',
      unit_price_service_fee: 'Phí dịch vụ',
      added: 'Tăng thêm',
      reduce: 'Giảm trừ',
      added: 'Tăng thêm',
      total: 'Tổng tiền',
      select_building_unit: 'Chọn phòng',
      details: 'Chi tiết',
      close: 'Đóng',
      detail_invoice: 'Chi tiết hóa đơn',
      input_month: 'Vui lòng nhập tháng',
      print_x: 'In',
      download: 'Tải xuống',
      edit_or_delete: 'Sửa / Xóa',
      edit_invoice: 'Sửa hóa đơn',
      submit: 'Lưu lại',
      back: 'Trở về',
      save_change: 'Lưu thay đổi',
      self: 'Hóa đơn',
      info: 'Thông tin',
      delete_invoice: 'Xóa hóa đơn',
      deleted: 'Đã xóa',
      confirmation: {
        perform_delete_invoice: 'Bạn có muốn xóa hóa đơn',
        yes: 'Có',
        close: 'Đóng'
      },
      search: 'Tìm kiếm',
      placeholder_search: 'Nhập tên phòng, từ khóa cần tìm',
      filter: 'Bộ lọc',
      month_names: {
        1: 'Tháng 1',
        2: 'Tháng 2',
        3: 'Tháng 3',
        4: 'Tháng 4',
        5: 'Tháng 5',
        6: 'Tháng 6',
        7: 'Tháng 7',
        8: 'Tháng 8',
        9: 'Tháng 9',
        10: 'Tháng 10',
        11: 'Tháng 11',
        12: 'Tháng 12'
      },
      reset: 'Reset',
      loadding: 'Loadding ...',
      loaded: 'Load done',
      list_invoices: 'Danh sách hóa đơn',
      note: 'Ghi chú',
      copy_image: 'Sao chép ảnh',
      generate_image_in_progress: "Đang thực hiện xuất ảnh"
    },
    revenues: {
      time: 'Thời gian',
      building_unit: 'Phòng',
      report_type: 'Loại thời gian',
      pla_unit_number: 'Chọn phòng',
      start_date: 'Ngày bắt đầu',
      pla_start_date: 'Chọn ngày bắt đầu',
      end_date: 'Ngày kết thúc',
      pla_end_date: 'Chọn ngày kết thúc',
      filter: 'Lọc',
      month: 'Tháng/ Năm',
      building_unit_price: 'Tiền phòng',
      electric: 'Điện',
      water: 'Nước',
      internet: 'Internet',
      price_parking_fee: 'Gửi xe',
      service: 'Dịch vụ',
      reduce: 'Giảm trừ',
      total_income: 'Tổng',
      search: 'Tìm kiếm',
      blank: 'Không được để trống',
      added: 'Tăng thêm'
    },
    contract_history: {
      id: 'Mã',
      time: 'Thời gian',
      building_unit: 'Phòng',
      type_of_transaction: 'Loại giao dịch',
      host: 'Người cho thuê',
      holder_name: 'Người thuê',
      code: {
        add: 'Kí mới',
        renew: 'Gia hạn',
        terminate: 'Hủy hợp đồng'
      }
    },
    upload_file: {
      import_file: 'Nhập hàng loạt',
      close: 'Đóng',
      upload: 'Upload'
    },
    flash: {
      success: 'Thành công!',
      unsuccess: 'Không thành công!'
    },
    paginate: {
      result: 'Tổng số: '
    },
    input_image_file: {
      remove: 'Loại bỏ',
      select_file: 'Chọn tệp'
    },
    export: {
      csv: 'Xuất CSV'
    },
    per_page: {
      show: 'Hiển thị',
      items: 'bản ghi'
    },
    admin: {
      full_name: 'Họ và Tên',
      profile: 'Thông tin tài khoản',
      phone: 'Số điện thoại',
      birthday: 'Ngày sinh',
      password: 'Mật khẩu',
      password_confirmation: 'Xác nhận mật khẩu',
      email: 'Email',
      save: 'Lưu lại'
    },
    organization: {
      organization_id: 'Mã tổ chức',
      name: 'Tên tổ chức',
      description: 'Mô tả'
    },
    building_unit: {
      document: "Tài liệu",
      sub_merchant: {
        title: "Ngân hàng"
      }
    },
    sub_merchant: {
      edit: 'Sửa tài khoản ngân hàng'
    }
  }
};

export default messages;
